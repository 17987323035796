import { ReactElement, ReactNode } from 'react';
import {  RootState } from '../../store';
import {  useSelector } from 'react-redux';
import { PAGE_URL } from '../../constants';
import { CAB_UI_EVENT } from '../../uiEvents';
import { AppBar, Box, IconButton, Toolbar, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CabIcon } from '@CabComponents/CabIcon';
import { IoMenu } from 'react-icons/io5';
import colors from '../../colors';

interface ComponentProps {
  color?: string;
  backgroundColor?: string;
  noAuth?: boolean;
  children?: ReactNode;
  noBorder?: boolean;
}


type Props = ComponentProps;

export const AppHeader = ({
  color, backgroundColor, children, noAuth, noBorder
}: Props): ReactElement => {
  
  const isAuthenticated = useSelector((root: RootState) => root.auth.isAuthenticated);
  const location = useLocation();

  const handleOpenMenu = (): void => {
    // Using an event handler like this allows us to open the nav from the app bar on any page,
    //    but is generally not how we should do UI operations. This is a use case where any other way would
    //    be overcomplicated right now.
    
    window.dispatchEvent(CAB_UI_EVENT.NAV_OPEN);
  };

  return (
    <AppBar position="static" 
      sx={{boxShadow: 'none', backgroundColor: colors.white900, zIndex: 0, display: 'flex',
        borderBottom: (!noBorder && !!children) ? `1px solid ${colors.black200}` : undefined}}>
      {location.pathname !== PAGE_URL.LOGIN &&
        location.pathname !== PAGE_URL.UPDATE && 
          location.pathname !== PAGE_URL.MAINTENANCE &&
          (isAuthenticated || noAuth) &&
            <Toolbar
              color={color || colors.white900}
              sx={{width: '100%', backgroundColor: backgroundColor, display: 'flex', alignItems: 'center',
                borderStyle: 'none', '&.MuiToolbar-root': {padding: '0px 8px', minHeight: '55px'}}}>
              {isAuthenticated && (
                <Box display='flex' justifyContent={'flex-start'}>
                  <MenuButton onDashboard={location.pathname === PAGE_URL.DASHBOARD} onClick={handleOpenMenu}>
                    <CabIcon Icon={IoMenu}/>
                  </MenuButton>
                </Box>
              )}
              <Box display='flex' width='100%' padding={.5}>
                {children}
              </Box>
            </Toolbar>
      }
    </AppBar>

  );
};

const MenuButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'onDashboard', label: "MenuButon"
})<{ onDashboard?: boolean }>(({ theme, onDashboard }) => ({
  ...(!onDashboard ? {} :
    {[theme.breakpoints.between('sm', 'lg')]: {
      color: colors.white900,
    },
    }
  ),
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

export default AppHeader;



