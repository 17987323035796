import { ReactElement, ReactNode } from 'react';
import { Box } from '@mui/material';

import AppHeader from '../AppHeader/AppHeader';
import { trackPageView } from '../../utils/appAnalyticsUtils';
import { useMountEffect } from '../../utils/hooks';
import { HEADER_HEIGHT } from '../../constants';

interface ComponentProps {
  pageName: string; 
  headerContent?: ReactNode;
  headerColor?: string;
  headerBackgroundColor?: string;
  headerNoAuth?: boolean;
  children: ReactNode;
  noHeader?: boolean;
  footer?: ReactNode;
  noBorder?: boolean;
}
const CabinetPage = ({
  pageName, children, headerContent, headerColor, headerBackgroundColor, headerNoAuth, noHeader, 
  footer, noBorder
}: ComponentProps): ReactElement => {

  useMountEffect(() => {
    trackPageView(pageName);
  });

  return (
    <Box 
      sx={{position: 'absolute', bottom: noHeader ? 0 : HEADER_HEIGHT, left: 0, right: 0}} 
      className={"page-content"}
    >
      {!noHeader && (
        <AppHeader 
          color={headerColor} backgroundColor={headerBackgroundColor} noAuth={headerNoAuth} noBorder={noBorder}
        >
          {headerContent}
        </AppHeader>
      )}
      <Box component="main" display="flex" flexDirection="column" flex={1} 
        width="100%" height="100%" sx={{ overflowY: 'auto' }}>
        {children}
      </Box>
    </Box>
  );
};

export default CabinetPage;
